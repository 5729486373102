@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?dr2937');
  src:  url('fonts/icomoon.eot?dr2937#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?dr2937') format('truetype'),
    url('fonts/icomoon.woff?dr2937') format('woff'),
    url('fonts/icomoon.svg?dr2937#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\f00c";
}
.icon-chevron-circle-left:before {
  content: "\f137";
}
