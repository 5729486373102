@import url("css/icomoon/style.css");
body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #4D4D4D;
}

#screen404Content {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.flex-fill {
  flex: 1 1 auto;
}

.large-spinner {
  width: 4rem; 
  height: 4rem;
}


.error-banner {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
}
.error-banner p {
  margin: 0;
}

.overlay-large-spinner {
  position: absolute;
  top: 50%;
  text-align:center;
  z-index: 200;
  width: 4rem; 
  height: 4rem;
}

.overlay{
  z-index: 100;
  position: fixed;
	width: 100%;
	height: 100%;
  background:  #4D4D4D;
}

.fadeOut{
  opacity:0;
  width:0;
  height:0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}


